import CallToActions from "./CallToActions";
import SingleProcessBlock from "./SingleProcessBlock";
import GoogleAd from "../GoogleAd";
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const videoUrls = [
  {video: '/images/Bybit.mp4', href: "https://www.bybit.com/invite?ref=QXAXZG"},
  {video: '/images/Nexo.mp4', href: "https://nexo.com/ref/gxq3nzjg2q?src=ios-link"},
];
const ProcessCta = () => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentVideoIndex((currentVideoIndex + 1) % videoUrls.length);
    }, 8000);
    return () => clearInterval(intervalId);
  }, [currentVideoIndex]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
     
    }

  }, []);
  return (
    <>
    
      {/* <!-- Process / CTA --> */}
      <section className="pt-24 " ref={targetRef}>
      {/* <GoogleAd/> */}
        <div className="container" style={{borderRadius: 10, height: 145}} >
          {/* <div className="mx-auto mb-12 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Get started in a few minutes
            </h2>
            <p className="text-lg dark:text-jacarta-300">
              Supports a variety of the most popular digital currencies.
            </p>
          </div> */}
          {/* End mx-auto */}

          {/* <div className="mb-20 lg:flex lg:flex-nowrap lg:space-x-8">
            <SingleProcessBlock />
          </div> */}

 {/* <div className="video-container">
      <TransitionGroup>
        <CSSTransition key={currentVideoIndex} timeout={1000} classNames="fade">
          <video
            src={videoUrls[currentVideoIndex]}
            autoPlay
            muted
            className="video"
            onEnded={() =>
              setCurrentVideoIndex((currentVideoIndex + 1) % videoUrls.length)
            }
          />
        </CSSTransition>
      </TransitionGroup> */}

      {
              <a target="_blank" href={videoUrls[currentVideoIndex].href} style={{cursor: 'pointer',  height:  dimensions.width /10, paddingBottom: 20, display: 'block'}}>

              <video 
             style={{borderRadius: 10}}
            
                  src={videoUrls[currentVideoIndex].video}
                  autoPlay={true} 
                  controls={false} 
                  loop={true}
                  muted={true}
                  playsInline={true}
                />
                </a>

}
{/* {!isDesktop && (
                <a target="_blank" href={videoUrls[currentVideoIndex].href} style={{cursor: 'pointer'}}>

<video 
// style={{borderRadius: 10, height: 80}}
style={{borderRadius: 10}}

      src={videoUrls[currentVideoIndex].video}
      autoPlay={true} 
      controls={false} 
      loop={true}
      muted={true}
      playsInline={true}
    />
    </a>
    )} */}

          {/* <CallToActions /> */}
        </div>
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default ProcessCta;
