import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Slider from 'react-slick';
import TextLoop from "react-text-loop";

const Wrapper = styled.div`
  overflow: hidden;
`;

const Line = styled.div`
  white-space: nowrap;
  animation: slide 3s linear infinite;
`;

const LineItem = styled.span`
  display: inline-block;
  padding-right: 20px;
`;

const LineContainer = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/global?x_cg_pro_api_key=CG-bA9MzHY9nFwsHkLPZZD2KDsy"
      );
      setData(response.data.data);
    };
    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed:2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
          <Slider {...settings}>

        <TextLoop interval={1000}>
          {Object.entries(data).map(([key, value]) => (
            <p key={key}>
              {key}: {JSON.stringify(value)}
            </p>
          ))}
        </TextLoop>
      
      </Slider>

    </div>

  );
};

export default LineContainer;
