import Partners2 from "../../components/partners/Partners2";
import CryptoCounter from "../../components/cryto-trading/CryptoCounter";
import CryptoPrice from "../../components/cryto-trading/price";
import Features from "../../components/cryto-trading/Features";
import InvestEarn from "../../components/cryto-trading/invest-earn";
import ProcessCta from "../../components/cryto-trading/ProcessCta";
import ProcessVideo from "../../components/cryto-trading/ProcessVideo";
import Hero_11 from "../../components/hero/hero_11";
import Meta from "../../components/Meta";
import Header01 from "../../components/header/Header01";
import SliderContainer from "../../components/SliderContainer";

import { useRouter } from "next/router";

const Home_1 = () => {
  const router = useRouter();

  const { locales, locale: activeLocale } = router;

  return (
    <main >     
      <Meta title="YallaCap" />
      {/* <Header01 /> */}
      <ProcessVideo />

      {/* <SliderContainer/> */}

      <InvestEarn />
      <CryptoPrice />

      <ProcessCta />


      {/* <Partners2 /> */}
    </main>
  );
};

export default Home_1;
