import CallToActions from "./CallToActions";
import SingleProcessBlock from "./SingleProcessBlock";
import GoogleAd from "../GoogleAd";
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const videoUrls = [
  {video: '/images/Binance.mp4', href: "https://accounts.binance.com/register?ref=VF4H50NR"},
  {video: '/images/Kucoin.mp4', href: "https://www.kucoin.com/ucenter/signup?rcode=85uvce"},
  {video: '/images/yl.mp4', href: "https://yallacap.com"},
];

const ProcessVideo = () => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentVideoIndex((currentVideoIndex + 1) % videoUrls.length);
    }, 7000);
    return () => clearInterval(intervalId);
  }, [currentVideoIndex]);


  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
     
    }

  }, []);
  return (
    <>
    
      {/* <!-- Process / CTA --> */}
      <section className="pt-24 pb-10" ref={targetRef}>
      {/* <GoogleAd/> */}
        <div className="container" >
          {/* <div className="mx-auto mb-12 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              Get started in a few minutes
            </h2>
            <p className="text-lg dark:text-jacarta-300">
              Supports a variety of the most popular digital currencies.
            </p>
          </div> */}
          {/* End mx-auto */}

          {/* <div className="mb-20 lg:flex lg:flex-nowrap lg:space-x-8">
            <SingleProcessBlock />
          </div> */}


{
  <a target="_blank" href={videoUrls[currentVideoIndex].href} style={{cursor: 'pointer',  height:  dimensions.width /10, paddingBottom: 20, display: 'block'}}>

  <video 
 style={{borderRadius: 10}}

      src={videoUrls[currentVideoIndex].video}
      autoPlay={true} 
      controls={false} 
      loop={true}
      muted={true}
      playsInline={true}
    />
    </a>
}

{/* 
{!isDesktop && (
  <a target="_blank" href={videoUrls[currentVideoIndex].href} style={{cursor: 'pointer'}}>

<video 
style={{borderRadius: 10, height: 80}}

      src={videoUrls[currentVideoIndex].video}
      autoPlay={true} 
      controls={false} 
      loop={true}
      muted={true}
      playsInline={true}
    />
        </a>

    )} */}


          {/* <CallToActions /> */}
        </div>
      </section>
      {/* <!-- end process / cta -->  */}
    </>
  );
};

export default ProcessVideo;
